import { colours, font_families } from "../../Styles";

const scoreCardStyles = {
  background: {
    backgroundColor: colours.roost_pink,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: "10px",
    padding: "20px",
    gap: "10px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  buttonStyles: {
    backgroundColor: colours.button_filling,
    color: colours.black,
    border: "none",
    borderRadius: "6px",
    padding: "10px 20px",
    fontSize: "16px",
    fontFamily: font_families.roost,
    cursor: "pointer",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.2s ease",
  },
  buttonStylesHover: {
    transform: "scale(1.05)",
  },
  title: {
    fontFamily: font_families.roost,
    fontSize: "32px",
    color: colours.white,
    marginBottom: "10px",
  },
  playerScores: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  },
  playerStyles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "5px",
    backgroundColor: colours.button_filling,
    transition: "box-shadow 0.2s ease",
    cursor: "pointer",
  },
  selectedPlayerStyles: {
    boxShadow: "0 0 10px 0 " + colours.roost_blue,
    border: `10px solid ${colours.roost_blue}`,
    padding: "0px",
  },
  inputField: {
    border: "none",
    borderRadius: "4px",
    textAlign: "center",
    fontSize: "16px",
    padding: "5px",
    width: "80%",
  },
  scoreText: {
    margin: "5px 0 0 0",
    fontSize: "24px",
    color: "#333",
  },
};

export default scoreCardStyles;
