import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { colours } from "./Styles";

import { RulesBooklet } from "./RuleModules";
import { Helmet } from "react-helmet";
import "./NotificationAnimation.css"; // Import the CSS file with animations

const NonStickyNotificationBar = (props) => {
  const [showNotification] = useState(true);

  const notificationStyle = {
    justify: "flex",
    display: showNotification ? "block" : "none",

    width: props.mobile ? "90%" : "30%",
    backgroundColor: "#fff",
    color: colours.roost_pink,

    zIndex: 999, // Ensure it's above other elements
    padding: 20,
    borderRadius: "10px", // Rounded corners
    border: "2px solid " + colours.roost_pink, // White outline
  };

  return (
    <div style={notificationStyle}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <a
          className="e-widget no-button"
          href="/scorecard"
          rel="nofollow"
          style={{ color: "#000", textDecoration: "none", margin: 0 }}
        >
          <div>
            <p style={{ margin: 0, fontWeight: "bold", fontSize: 20 }}>
              {" "}
              Digital Score Tracker?{" "}
            </p>
            <p style={{ margin: 0 }}>
              Keep track of scores with our online tool
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

function Rules() {
  const [renderType, setRenderType] = useState("row");
  const [buttonSize, setButtonSize] = useState("30%");
  const [mobileDevice, setMobileDevice] = useState(false);

  const button_style = {
    color: "white",
    fontSize: 16,
    height: 40,
    width: buttonSize,
    border: "none",
    padding: 10,
    borderRadius: 10,
    margin: 5,
  };

  useEffect(() => {
    if (isMobile) {
      setRenderType("column");
      setButtonSize("100%");
      setMobileDevice(true);
    }
  }, []);


  return (
    <div
      className="rules"
      style={{ paddingBottom: 100, backgroundColor: "#f7c9e5" }}
    >
      <Helmet>
        <title>Rules - roostgame.com</title>
        <meta name="description" content="How to player Roost" />
      </Helmet>

      <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            paddingTop: 20,
          }}
        >
          <NonStickyNotificationBar mobile={mobileDevice} />
        </div>
      <div class="container">
        <div
          style={{
            display: "flex",
            flexDirection: renderType,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <RulesBooklet />
        </div>

      </div>
    </div>
  );
}

export default Rules;
