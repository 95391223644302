import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import pink_k from "../../images/LandingPage/chicken_white.png";
import { FaFeather } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import "./landingpage.css";
import { PurchaseModuleStyles } from "./PurchaseModuleStyles/PurchaseModuleStyles";

const myAPI = "mailchimp";

function PurchaseModuleSimple() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [classname, setClassname] = useState("jumping-image");
  useEffect(() => {
    if (isMobile) {
      setClassname("jumping-image-phone");
    }
  }, []);

  const addEmail = () => {
    if (email === "") {
      setError("Please enter an email address");
      return;
    }
    const data = {
      firstName: "Landing",
      lastName: "Page",
      email: email,
      refer: "",
    };
    API.post(myAPI, "/mailchimp", { body: data })
      .then((response) => {
        if(response=='Member already exists'){
          setError("You have already joined the mailing list!");

        }else{
        setError("Thankyou for joining the mailing list!");
        }
      })
      .catch((error) => {
        setError("There was an error with joining the waitlist");
      });
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div style={PurchaseModuleStyles.container}>
      <div style={{ padding: 20 }}>
        <p
          style={{
            fontSize: "16px",
            paddingBottom: 5,
            marginBottom: 0,
            color: "#ed1a84",
          }}
        >
          Sign up now to join the <b>Roost Community</b>. Find out first about
          expansions, giveways, free cards and things we're working on.
        </p>

        <div
          style={{
            marginBottom: 0,
            display: "flex",
            width: "100%",
            height: "50px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              alignItems: "center",
              height: "100%",
              paddingRight: 5,
            }}
          >
            <label
              style={{
                margin: 0,
                width: "100%",
                height: "100%",
                padding: 0,
                display: "flex",
              }}
            >
              <input
                type="text"
                value={email}
                onChange={handleChangeEmail}
                placeholder={email === "" ? "your@email.com" : ""}
                style={PurchaseModuleStyles.inputStyle}
              />
            </label>
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "20%",
            }}
          >
            <button onClick={addEmail} style={PurchaseModuleStyles.buttonStyle}>
              <FaFeather
                className="bird-icon"
                style={PurchaseModuleStyles.feather}
              />{" "}
            </button>
          </div>
        </div>
        <div>
          <a href="/shop" style={PurchaseModuleStyles.shopButtonHyperlink}>
            <button
              style={{
                backgroundColor: "#ed1a84",
                borderRadius: "15px",
                color: "#ffffff",
                border: "none",
                outline: "none",
                justifyContent: "center",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "50px",
                width: "100%",
                marginBottom: 0,
              }}
            >
              <p
                style={{
                  marginBottom: 0,
                  paddingTop: 5,

                  fontSize: "16px",
                }}
              >
                Check out <b>Roost</b>
              </p>

              <img
                src={pink_k}
                className={classname}
                style={{
                  height: "100%",
                  width: "auto",
                  padding: 7,
                  marginLeft: 5,
                }}
                alt="Pink Cockatoo"
              ></img>
            </button>
          </a>
        </div>
        {error !== "" ? (
          <p style={PurchaseModuleStyles.errorStyle}>
            <i>{error}</i>
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default PurchaseModuleSimple;
