import React from "react";
import { API } from "aws-amplify";
import { isMobile } from "react-device-detect";
const myAPI = "mailchimp";

function PurchaseModule() {
  const [firstName, setFirstName] = React.useState("");
  const [refer, setRefer] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");

  //Function to fetch from our backend and update customers array
  function addEmail(e) {
    if (email === "") {
      setError("Please enter an email address");
      return;
    }
    let data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      refer: refer,
    };
    API.post(myAPI, "/mailchimp", { body: data })
      .then((response) => {
        if(response=='Member already exists'){
          setError("You have already joined the mailing list!");

        }else{
        setError("Thankyou for joining the mailing list!");
        }
      })
      .catch((error) => {
        console.log(error);
        setError("There was an error with joining the mailing list");
      });
  }

  function handleChangeEmail(event) {
    setEmail(event.target.value);
  }

  function handleChangeFirstName(event) {
    setFirstName(event.target.value);
  }

  function handleChangeLastName(event) {
    setLastName(event.target.value);
  }
  function handleChangeRefer(event) {
    setRefer(event.target.value);
  }

  return (
    <>
      {isMobile ? (
        <>
          <label style={{ marginBottom: 0, fontWeight: "350" }}>
            *First Name:
            <br></br>
            <input
              type="text"
              value={firstName}
              onChange={handleChangeFirstName}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                margin: 0,
                padding: 5,
                border: "1px solid #ccc",
                fontFamily: "Gotham, sans-serif",
                fontWeight: "lighter",
                fontSize: "12px",
                opacity: "0.85",
              }}
              placeholder={email === "" ? "First Name" : ""}
            />
          </label>
          <label style={{ marginBottom: 0, fontWeight: "350" }}>
            Last Name:
            <br></br>
            <input
              type="text"
              value={lastName}
              onChange={handleChangeLastName}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                margin: 0,
                padding: 5,
                border: "1px solid #ccc",
                fontFamily: "Gotham, sans-serif",
                fontWeight: "lighter",
                fontSize: "12px",
                opacity: "0.85",
              }}
              placeholder={lastName === "" ? "Last Name" : ""}
            />
          </label>
        </>
      ) : (
        <div style={{ flexDirection: "row", marginBottom: 10 }}>
          <label
            style={{ marginBottom: 0, fontWeight: "350", marginRight: 20 }}
          >
            *First Name:
            <br></br>
            <input
              type="text"
              value={firstName}
              onChange={handleChangeFirstName}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                margin: 0,
                padding: 5,
                border: "1px solid #ccc",
                fontFamily: "Gotham, sans-serif",
                fontWeight: "lighter",
                fontSize: "12px",
                opacity: "0.85",
              }}
              placeholder={email === "" ? "First Name" : ""}
            />
          </label>
          <label style={{ marginBottom: 0, fontWeight: "350" }}>
            Last Name:
            <br></br>
            <input
              type="text"
              value={lastName}
              onChange={handleChangeLastName}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                margin: 0,
                padding: 5,
                border: "1px solid #ccc",
                fontFamily: "Gotham, sans-serif",
                fontWeight: "lighter",
                fontSize: "12px",
                opacity: "0.85",
              }}
              placeholder={lastName === "" ? "Last Name" : ""}
            />
          </label>
        </div>
      )}
      <label style={{ fontWeight: "350" }}>
        <b>*</b>Email:
        <br></br>
        <input
          type="text"
          value={email}
          onChange={handleChangeEmail}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "5px",
            margin: 0,
            padding: 5,
            border: "1px solid #ccc",
            fontFamily: "Gotham, sans-serif",
            fontWeight: "lighter",
            fontSize: "12px",
            opacity: "0.85",
          }}
          placeholder={email === "" ? "your@email.com" : ""}
        />
      </label>
      {/* <label style={{ fontWeight: "350" }}>
        Referral Email:
        <br></br>
        <input
          type="text"
          value={refer}
          onChange={handleChangeRefer}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "5px",
            margin: 0,
            padding: 5,
            border: "1px solid #ccc",
            fontFamily: "Gotham, sans-serif",
            fontWeight: "lighter",
            fontSize: "12px",
            opacity: "0.85",
          }}
          placeholder={refer === "" ? "Referral@email.com" : ""}
        />
      </label> */}
      <p style={{ fontSize: 10 }}>* Mandatory field</p>
      {error !== "" ? (
        <div style={{ padding: 10, width: "100%" }}>
          <p>
            <i>{error}</i>
          </p>
        </div>
      ) : (
        <></>
      )}

      <button
        onClick={addEmail}
        style={{
          color: "white",
          backgroundColor: "#EC2383",
          fontSize: 20,
          height: 40,
          width: "80%",
          border: "none",
          marginBottom: 30,
          borderRadius: 10,
        }}
      >
        Join the Mailing List
      </button>
    </>
  );
}

export default PurchaseModule;
