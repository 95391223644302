import React, { useState, useEffect } from "react";
import { colours, font_families } from "../../Styles";
import scoreCardStyles from "./ScoreCardStyle";

function ScoreCardModule() {
  const [scores, setScores] = useState(() => {
    return JSON.parse(localStorage.getItem("scores")) || Array(6).fill(0);
  });

  const [playerNames, setPlayerNames] = useState(() => {
    return (
      JSON.parse(localStorage.getItem("playerNames")) || [
        "Player 1",
        "Player 2",
        "Player 3",
        "Player 4",
        "Player 5",
        "Player 6",
      ]
    );
  });

  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [directionToken, setDirectionToken] = useState("Clockwise");

  // LocalStorage Updates
  useEffect(() => {
    localStorage.setItem("scores", JSON.stringify(scores));
  }, [scores]);

  useEffect(() => {
    localStorage.setItem("playerNames", JSON.stringify(playerNames));
  }, [playerNames]);

  // Handlers
  const handleScoreUpdate = (value) => {
    if (selectedPlayer !== null) {
      const updatedScores = [...scores];
      updatedScores[selectedPlayer] += value;
      setScores(updatedScores);
    }
  };

  const handleNameChange = (index, newName) => {
    const updatedNames = [...playerNames];
    updatedNames[index] = newName;
    setPlayerNames(updatedNames);
  };

  const handleReset = () => setScores(Array(6).fill(0));

  const handlePlayerClick = (index) => {
    setSelectedPlayer((prev) => (prev === index ? null : index));
  };

  const handleDirectionToken = () =>
    setDirectionToken((prev) =>
      prev === "Clockwise" ? "Anti Clockwise" : "Clockwise"
    );

  const buttonTypes = [
    { label: "-5", action: () => handleScoreUpdate(-5) },
    { label: "-1", action: () => handleScoreUpdate(-1) },
    { label: "+1", action: () => handleScoreUpdate(1) },
    { label: "+5", action: () => handleScoreUpdate(5) },
    { label: "+10", action: () => handleScoreUpdate(10) },
    { label: "Reset", action: handleReset },
    {
      label: directionToken,
      action: handleDirectionToken,
      style: { backgroundColor: colours.yellow },
    },
  ];

  return (
    <div style={scoreCardStyles.background}>
      <div style={scoreCardStyles.buttonContainer}>
        <h1 style={scoreCardStyles.title}>Scorecard</h1>
        {buttonTypes.map((button, index) => (
          <button
            key={index}
            style={{ ...scoreCardStyles.buttonStyles, ...button.style }}
            onClick={button.action}
          >
            {button.label}
          </button>
        ))}
      </div>
      <div style={scoreCardStyles.playerScores}>
        {scores.map((score, index) => (
          <div
            key={index}
            style={
              index === selectedPlayer
                ? {
                    ...scoreCardStyles.playerStyles,
                    ...scoreCardStyles.selectedPlayerStyles,
                  }
                : scoreCardStyles.playerStyles
            }
            onClick={() => handlePlayerClick(index)}
          >
            <input
              style={scoreCardStyles.inputField}
              type="text"
              value={playerNames[index]}
              onChange={(e) => handleNameChange(index, e.target.value)}
            />
            <p style={scoreCardStyles.scoreText}>{score}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ScoreCardModule;
