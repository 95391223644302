import React, { useState, useEffect } from "react";
import rich_rooster from "../../images/newbird.png";
import { colours } from "../Styles";

const RichRoosterSticky = (props) => {
  const [showNotification] = useState(true);

  const notificationStyle = {
    justify: "flex",
    display: showNotification ? "block" : "none",

    width: props.mobile ? "90%" : "100%",
    backgroundColor: "#fff",
    color: colours.roost_pink,

    zIndex: 999, // Ensure it's above other elements
    padding: 20,
    borderRadius: "10px", // Rounded corners
    border: "2px solid " + colours.roost_pink, // White outline
  };

  const text = "Sign up to our mailing list for a free Rich Rooster card*";

  const [highlightIndex, setHighlightIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setHighlightIndex((prevIndex) => (prevIndex + 5) % text.length); // Cycle through characters
    }, 200); // Adjust timing for the speed of the highlight
    return () => clearInterval(interval); // Clean up on component unmount
  }, [text]);

  return (
    <div style={notificationStyle}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <img
          src={rich_rooster}
          alt="Notification icon"
          style={{ width: "70px", marginRight: "30px" }}
        />
        <a
          className="e-widget no-button"
          href="/shop"
          rel="nofollow"
          style={{ color: "#000", textDecoration: "none", margin: 0 }}
        >
          <div>
            <p style={{ margin: 0, fontWeight: "bold", fontSize: 20 }}>
              {" "}
              Rich Rooster{" "}
            </p>
            <p style={{ fontSize: "10px" }}>
              {text.split("").map((char, index) => (
                <span
                  key={index}
                  style={{
                    color:
                      index >= highlightIndex && index < highlightIndex + 5
                        ? "red"
                        : "black", // Highlight 5 characters
                    transition: "color 0.2s ease-in-out", // Smooth color transition
                  }}
                >
                  {char}
                </span>
              ))}
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default RichRoosterSticky;
