import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { colours, font_families } from "./Styles";
import "./NotificationAnimation.css"; // Import the CSS file with animations
import { PurchaseModule } from "./PurchaseModules";
import { RichRoosterSticky } from "./Community";
import { ContactScreen } from "./ContactScreen";

function Purchase() {
  const [direction, setDirection] = useState("row");
  const [mobileDevice, setMobileDevice] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setDirection("column");
      setMobileDevice(true);
    }
  }, []);

  return (
    <div
      className="about"
      style={{
        paddingBottom: 200,
        fontFamily: font_families.roost,
        backgroundColor: "#f7c9e5",
      }}
    >
      <Helmet>
        <title>Community - Roost Game</title>
        <meta name="description" content="Join the Roost community" />
      </Helmet>

      <div className="container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "Gotham, sans-serif",
            fontWeight: "lighter",
            color: "#ed1a84",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        <ContactScreen/>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              margin: 20,
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: 20,
              textAlign: "center",
              backgroundColor: "#FFF",
            }}
          >
            <p style={{ fontWeight: "350", paddingBottom: 10, marginBottom: 0 }}>
              <i>
                Stay at top of the pecking order by joining the mailing list
              </i>
            </p>

            <PurchaseModule />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 10,
                width: "100%",
                padding: 10,
              }}
            >
              <RichRoosterSticky mobile={mobileDevice} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            ></div>
          </div>
        </div>
      </div>

      <div style={{ padding: 20, marginTop: 30 }}>
        <p style={{ fontSize: 10 }}>
          * You must purchase a copy of Roost to obtain the Rich Rooster card.
          Rich Rooster will be delivered with your copy of Roost if purchased
          via this website.
        </p>
      </div>
    </div>
  );
}

export default Purchase;
