import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Rules,
  Contact,
  Supporters,
  Blog,
  Scorecard,
  LandingPage,
  Enquiries,
  PrivacyPolicy,
  Shop,
  Purchase
} from "./components";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

// Import the Google Fonts link
const googleFontsLink = document.createElement("link");
googleFontsLink.rel = "stylesheet";
googleFontsLink.href =
  "https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap";
document.head.appendChild(googleFontsLink);

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/rules" element={<Rules />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/purchase" element={<Purchase />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/scorecard" element={<Scorecard />} />
      <Route path="/supporters" element={<Supporters />} />
      <Route path="/enquiries" element={<Enquiries />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/landingpage" element={<LandingPage />} />
      <Route path="/shop" element={<Shop />} />
    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root"),
);

serviceWorker.unregister();
